import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'

interface Options {
  operationName: string
  variables: Record<string, unknown>
  query: string
}

export async function requestGraphql<Result = unknown>({
  operationName,
  variables,
  query,
}: Options): Promise<Result | undefined> {
  if (!process.env.GATSBY_DOUBLE_GQL_ENDPOINT) {
    throw new Error('GATSBY_DOUBLE_GQL_ENDPOINT is not set')
  }

  const requestId = uuid()
  const response = await window.fetch(process.env.GATSBY_DOUBLE_GQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'apollographql-client-name': 'www',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Request-ID': requestId,
      'X-TimeZone': DateTime.local().zoneName,
    },
    body: JSON.stringify({
      operationName,
      variables,
      query,
    }),
  })

  const { data, errors } = (await response.json()) as {
    data?: Result & {
      errors?: unknown[]
    }
    errors?: unknown[]
  }

  if (!response.ok || data?.errors?.length) {
    console.error(`Failed to request Graphql operationName=${operationName}`, {
      operationName,
      requestId,
      errors,
      dataErrors: data?.errors,
    })

    throw new Error(
      `Failed to request Graphql operationName=${operationName} requestId=${requestId}`,
    )
  }

  return data
}
