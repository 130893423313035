import React, { useEffect } from 'react'
import styled from 'styled-components'

import _CheckIcon from '../../../components/create-suggested-task/check.inline.svg'
import { createTodo } from '../../../components/create-suggested-task/utils'
import Page from '../../../components/layout/Page'
import { Heading3 } from '../../../components/typography'
import useQueryOrStorageParam from '../../../hooks/useQueryOrStorageParam'

const Confirmation = styled.div`
  text-align: center;
`

const CheckIcon = styled(_CheckIcon)`
  margin-top: 80px;
`

const ConfirmationHeading = styled.div`
  padding-bottom: 168px;
`

const CreateTodo = () => {
  const userId = useQueryOrStorageParam('userId')
  const templateId = useQueryOrStorageParam('templateId')
  const assistantGivenName = useQueryOrStorageParam('ea', 'your double')

  useEffect(() => {
    // Create todo from template
    if (userId && templateId) {
      createTodo({
        userId,
        templateId,
      }).catch((error: Error) => {
        console.error('Error auto-submiting feedback', error)
      })
    }
  }, [userId, templateId])

  return (
    <Page
      head={{
        title: `Suggestion sent to ${assistantGivenName}!`,
      }}
      nav={false}
    >
      <Confirmation>
        <CheckIcon />
        <ConfirmationHeading>
          <Heading3>{`Suggestion sent to ${assistantGivenName}!`}</Heading3>
        </ConfirmationHeading>
      </Confirmation>
    </Page>
  )
}

export default CreateTodo
