import { requestGraphql } from '../api'

interface CreateTodoArgs {
  userId: string
  templateId: string
}

export function createTodo({ userId, templateId }: CreateTodoArgs) {
  return requestGraphql({
    operationName: 'createTodoFromWebsite',
    variables: {
      input: {
        userId,
        templateId,
      },
    },
    query: `
      mutation createTodoFromWebsite ($input: UnsafeCreateTodoFromTemplateInput!) {
        result: unsafeCreateTodoFromTemplate (input: $input) { todoId workspaceId }
      }
    `,
  })
}
